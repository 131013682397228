.factsheet-grid {
  width: 100%;
  .factsheet {
    padding: 0;
    width: 32%;
    float: left;
    margin-bottom: 10px;
    position: absolute;

    .factsheet-image {
      width: 100%;
      height: 100%;
    }

    .factsheet-info {
      padding: 10px;
      background-color: rgba($tealColor, 0.7);
      transition-duration: 0.3s;
      position: absolute;
      bottom: 0;
      // transform: translateY(100%);
      left: 0;
      width: 100%;
      backdrop-filter: blur(3px);

      .factsheet-title {
        margin-bottom: 0;
        color: white;
      }

      .factsheet-button {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: $darkBlueColor;
        height: 0;
        text-decoration: none;
        opacity: 0;
        width: 100%;
        text-align: right;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        transform-origin: right;

        animation-duration: 0.3s;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
      }

      &:hover {
        .factsheet-button {
          transform: scale(1.02);
          font-weight: bold;
        }
      }
    }

    .factsheet-tag {
      position: absolute;
      color: white;
      font-size: 14px;
      top: 10px;
      left: 10px;
      background: $tealColor;
      padding: 5px 10px;
      border-radius: 5px;
      opacity: 0.5;
      transition-duration: 0.3s;
    }

    &:hover {
      .factsheet-info {
        background-color: rgba($tealColor, 1);
        backdrop-filter: none;
        padding-bottom: 30px;

        .factsheet-button {
          animation-name: fadeIn;
        }
      }

      .factsheet-tag {
        opacity: 1;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@keyframes fadeIn {
  0% {
    height: 0;
    opacity: 0;
  }

  1% {
    height: auto;
    opacity: 0;
  }

  100% {
    height: auto;
    opacity: 1;
  }
}

.factsheet-selectors {
  display: flex;
  gap: 20px;

  .factsheet-selector {
    text-decoration: none;
    font-weight: normal;
    cursor: pointer;
    transition-duration: 0.3s;

    &.active {
      color: black;
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
    }
  }
}
