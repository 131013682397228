/* GLOBAL VARIABLES */

$headers: 'Invention Light', 'Helvetica', Arial, sans-serif;
$bodyCopy: 'Invention Light', 'Helvetica', Arial, sans-serif;

/* PRIMARY COLORS */
$textColor: #000000;
$linkColor: #00857c;
$whiteColor: #FFFFFF;
$offWhiteColor: #f7f7f7;
$tealColor: #00857c;
$lightTealColor: #6eceb2;
$darkBlueColor: #0c2340;

/* SECONDARY COLORS */
$limeColor: #bfed33;
$lemonColor: #fff063;
$pastelBlueColor: #69b8f7;
$vistaBlueColor: #688ce8;
$richBlueColor: #5450e4;

/* Added Colors */
$redColor: #be0a0a; // Error messages

/* UI COLORS */
$tealHoverColor: #005c55;
$grayColor: #757575;
$lightGrayColor: #cfd2d7;
$whiteGrayColor: #e7e8ea;

/* SIZES */
$full-width: 100%;
$half-width: 50%;
$quarter-width: 25%;

/* CARD OPTIONS */
$card-spacer-x: 5px;
$card-spacer-y: 5px;
$card-img-overlay-padding: 5px;
$card-deck-margin: 5px;
$card-group-margin: 5px;
$card-columns-margin: 5px;
$card-columns-count: 1;
$card-columns-gap: 5px;


/* MEDIA BREAKS */
$break-xlarge: 1600px;
$break-large: 1200px;
$break-medium: 768px;
$break-small: 640px;
$break-xsmall: 360px;