/* Color Palette */
.whiteColor { color: $whiteColor; }
.offWhiteColor { color: $offWhiteColor; }
.tealColor { color: $tealColor; }
.lightTealColor { color: $lightTealColor; }
.tealHoverColor { color: $tealHoverColor; }
.lightBlueColor { color: $pastelBlueColor; }
.blueColor { color: $vistaBlueColor; }
.richBlueColor { color: $richBlueColor; }
.darkBlueColor { color: $darkBlueColor; }
.limeColor { color: $limeColor; }
.lemonColor { color: $lemonColor; }
.grayColor { color: $grayColor; }
.lightGrayColor { color: $lightGrayColor; }
.whiteGrayColor { color: $whiteGrayColor; }
.redColor { color: $redColor; }

/* Backgrounds */
.whiteColorBkgd { background-color: $whiteColor; }
.offWhiteColorBkgd { background-color: $offWhiteColor; }
.tealColorBkgd { background-color: $tealColor; }
.lightTealColorBkgd { background-color: $lightTealColor; }
.tealHoverColorBkgd { background-color: $tealHoverColor; }
.lightBlueColorBkgd { background-color: $pastelBlueColor; }
.blueColorBkgd { background-color: $vistaBlueColor; }
.richBlueColorBkgd { background-color: $richBlueColor; }
.darkBlueColorBkgd { background-color: $darkBlueColor; }
.limeColorBkgd { background-color: $limeColor; }
.lemonColorBkgd { background-color: $lemonColor; }
.grayColorBkgd { background-color: $grayColor; }
.lightGrayColorBkgd { background-color: $lightGrayColor; }
.whiteGrayColorBkgd { background-color: $whiteGrayColor; }
