.comp-btn {
  font-weight: bold !important;
  border-radius: 25px !important;
  padding: 10px 30px !important;
}

.comp-line {
  width: 45%;
  height: 2px;
  margin-bottom: 15px;

  &.teal {
    background-color: rgb(0, 205, 179);
  }

  &.white {
    background-color: white;
  }

  &.purple {
    background-color: rgb(102, 137, 224);
  }
}

.comp-heading {
  font-size: 30px;
  padding-top: 0;
  line-height: 2.3rem;
}

.comp-body {
  font-size: 20px;
}

.video-wrapper {
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0 0 45px #666;
  box-shadow: 0 0 25px #666;
  width: 100%;
  z-index: 2;
  transition-duration: 0.3s;

  &:hover {
    .video-layover {
      opacity: 0.4;
    }

    -webkit-box-shadow: 0 0 45px rgb(59, 59, 59);
    box-shadow: 0 0 45px rgb(67, 67, 67);
  }

  @media only screen and (max-width: 577px) {
    margin: 0 20px;
  }

  .video-layover {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 23, 57);
    position: absolute;
    left: 0;
    top: 0;

    opacity: 0;
    transition-duration: 0.3s;
  }

  img {
    width: 100%;
    height: auto;
  }

  .video-content {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 20px;
    background-color: rgba(0, 23, 57, 0.5);
    padding: 30px;
    border-radius: 20px;

    @media only screen and (max-width: 767px) {
      width: 80%;
      transform: translate(-50%, -50%);
      left: 50%;
    }

    img {
      width: 75px;

      @media only screen and (max-width: 641px) {
        width: 50px;
      }
    }

    div {
      color: white;

      .video-title {
        font-size: 32px;
        font-weight: bold;
        transition-duration: 0.3s;
        line-height: 1.1;
        text-align: left;

        @media only screen and (max-width: 641px) {
          font-size: 22px;
          margin-bottom: 5px;
        }

        @media only screen and (max-width: 470px) {
          font-size: 20px;
        }
      }

      .video-subtitle {
        font-size: 26px;
        margin-bottom: 0;
        text-align: left;

        @media only screen and (max-width: 641px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 470px) {
          font-size: 16px;
        }
      }
    }
  }

  .video-offset-square {
    height: 30px;
    width: 30px;
    position: absolute;
    background-color: teal;
    left: -30px;
    bottom: -30px;
  }
}

.dot-array {
  background-image: url('/images/2022-compendium/dot-array.svg');
  background-size: 400px 400px;
  position: absolute;
  z-index: 1;

  &.carousel-dots-1 {
    width: 345px;
    height: 225px;
    top: 25px;
    left: 48px;

    @media only screen and (max-width: 1199px) {
      width: 266px;
      top: 105px;
      left: 26px;
    }

    @media only screen and (max-width: 830px) {
      width: 226px;
      top: 46px;
      left: -76px;
      height: 133px;
    }

    @media only screen and (max-width: 440px) {
      left: -136px;
    }

    &.no-image {
      width: 146px;
      height: 250px;
      left: -12px;
      top: 109px;

      @media only screen and (max-width: 991px) {
        left: -166px;
      }
    }
  }
  &.carousel-dots-2 {
    width: 235px;
    height: 113px;
    right: 0;
    bottom: 25px;
    @media only screen and (max-width: 1199px) {
      right: -82px;
      bottom: 0px;
    }

    @media only screen and (max-width: 830px) {
      display: none;
    }

    &.no-image {
      width: 165px;
      height: 242px;
      right: -90px;
      top: 0;

      @media only screen and (max-width: 991px) {
        right: -185px;
      }
    }
  }
}

.comp-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  overflow-x: hidden;

  .comp-row {
    margin-top: 120px;
    margin-left: 0;
    margin-right: 0;

    @media only screen and (max-width: 565px) {
      margin-top: 50px;
    }
  }

  .comp-header {
    position: relative;
    box-shadow: 0 6px 25px -2px #666666;

    .heading-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 80px;

      @media only screen and (max-width: 1535px) {
        top: 20px;
      }

      @media only screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        right: 0;
      }

      @media only screen and (max-width: 918px) {
        top: 10px;
      }

      @media only screen and (max-width: 400px) {
        top: 0px;
      }

      h1 {
        text-align: right;
        color: white;
        font-size: 72px;
        font-weight: bold;
        line-height: 5rem;

        @media only screen and (max-width: 991px) {
          line-height: 1;
          width: 50%;
          float: right;
        }

        @media only screen and (max-width: 918px) {
          font-size: 40px;
        }

        @media only screen and (max-width: 521px) {
          font-size: 24px;
        }

        span {
          font-size: 90px;
          line-height: 1;
          background-color: rgb(0, 23, 57);
          padding: 12px;
          font-family: 'Invention Black';
          display: inline-block;
          margin-top: 20px;

          @media only screen and (max-width: 918px) {
            font-size: 60px;
            padding: 6px;
          }

          @media only screen and (max-width: 521px) {
            font-size: 30px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .comp-section-one {
    position: relative;
    padding-top: 100px;

    @media only screen and (max-width: 565px) {
      padding-top: 50px;
    }

    .first-row {
      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.left-col {
          padding-right: 100px;

          @media only screen and (max-width: 1200px) {
            padding-right: 30px;
          }

          h2 {
            text-align: left;
          }
        }

        &.right-col {
          p {
            padding-top: 20px;
            line-height: 1.7;
            text-align: left;
          }

          a {
            margin-top: 20px;
          }
        }
      }
    }

    .second-row {
      .second-row-card {
        text-align: center;
        z-index: 2;

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        p {
          font-weight: bold;
        }

        .card-image-wrapper {
          border-radius: 50%;
          padding: 20px;
          width: 155px;
          height: 155px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px auto;

          &.one {
            background-color: teal;
          }

          &.two {
            background-color: rgb(0, 23, 57);
          }

          &.three {
            background-color: rgb(94, 204, 179);
          }

          img {
            width: 100px;
            height: 100px;
          }
        }

        .teal-text {
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 20px;
        }

        .black-text {
          margin: auto;
          font-size: 18px;
        }
      }
    }

    .third-row {
      margin-top: 130px;

      @media only screen and (max-width: 565px) {
        margin-top: 50px;
      }
    }

    .fourth-row {
      .left-col {
        @media only screen and (max-width: 991px) {
          order: 2;
        }
      }
      .right-col {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }
    }

    .section-one-dots-1 {
      height: 192px;
      width: 415px;
      top: 443px;
      left: -86px;

      @media only screen and (max-width: 991px) {
        top: 584px;
      }

      @media only screen and (max-width: 767px) {
        top: 963px;
        width: 194px;
        height: 374px;
      }

      @media only screen and (max-width: 415px) {
        top: 1063px;
        left: -135px;
      }
    }

    .section-one-dots-2 {
      height: 512px;
      width: 415px;
      top: 808px;
      left: 757px;

      @media only screen and (max-width: 991px) {
        top: 897px;
        left: 527px;
        width: 235px;
        height: 235px;
      }

      @media only screen and (max-width: 767px) {
        // top: 1779px;
        // left: 324px;
        top: 1608px;
        left: 301px;
      }

      @media only screen and (max-width: 575px) {
        top: 1599px;
        left: 369px;
        height: 160px;
        width: 160px;
      }

      @media only screen and (max-width: 560px) {
        top: 1373px;
        left: 390px;
      }

      @media only screen and (max-width: 522px) {
        top: 1417px;
        left: 345px;
      }

      @media only screen and (max-width: 459px) {
        top: 1496px;
        left: 260px;
      }

      @media only screen and (max-width: 428px) {
        top: 1535px;
        left: 240px;
      }

      @media only screen and (max-width: 410px) {
        top: 1566px;
        left: 225px;
      }

      @media only screen and (max-width: 402px) {
        top: 1600px;
        left: 215px;
      }

      @media only screen and (max-width: 383px) {
        top: 1597px;
        left: 198px;
      }

      @media only screen and (max-width: 357px) {
        top: 1594px;
        left: 180px;
      }

      @media only screen and (max-width: 334px) {
        top: 1611px;
        left: 166px;
      }
    }
  }

  .comp-carousel {
    height: 375px;
    z-index: 2;

    @media only screen and (max-width: 830px) {
      height: 317px;
    }

    @media only screen and (max-width: 383px) {
      height: 335px;
    }

    .carousel-inner {
      height: 100%;
      .carousel-item {
        height: 100%;
        overflow: hidden;

        &.dark-blue-background {
          background-color: rgb(0, 23, 57);

          .slide-content {
            .slide-main {
              .slide-name {
                background-color: rgb(0, 205, 179);
              }
            }
          }
        }

        &.teal-background {
          background-color: rgb(0, 205, 179);

          .slide-content {
            .slide-main {
              .slide-heading {
                color: rgb(0, 23, 57);
                font-weight: bold;
              }

              .slide-title {
                color: rgb(0, 25, 57);
              }

              .slide-name {
                background-color: rgb(0, 25, 57);
              }
            }
          }
        }

        .slide-content {
          position: relative;
          height: 100%;

          .slide-headshot {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 444px;
            z-index: 2;

            @media only screen and (max-width: 1200px) {
              width: 323px;

              &.carmen-villar {
                width: 300px;
              }
            }

            @media only screen and (max-width: 830px) {
              width: 256px;

              &.carmen-villar {
                width: 235px;
              }
            }

            @media only screen and (max-width: 592px) {
              left: -40px;
            }

            @media only screen and (max-width: 440px) {
              width: 220px;

              &.carmen-villar {
                width: 193px;
              }

              &.julie-fleischer {
                width: 205px;
              }
            }

            @media only screen and (max-width: 400px) {
              &.julie-fleischer {
                width: 190px;
              }
            }
          }

          .slide-main {
            position: absolute;
            left: 444px;
            top: 70px;
            z-index: 2;

            @media only screen and (max-width: 1200px) {
              left: 307px;
              top: 33px;
            }

            @media only screen and (max-width: 767px) {
              &.small-text {
                width: 70%;
                top: 10px;
              }
            }

            @media only screen and (max-width: 725px) {
              &.small-text {
                width: 65%;
              }
            }

            @media only screen and (max-width: 685px) {
              &.small-text {
                width: 60%;
              }
            }

            @media only screen and (max-width: 830px) {
              left: 243px;
            }

            @media only screen and (max-width: 592px) {
              left: 164px;
            }

            @media only screen and (max-width: 440px) {
              left: 140px;
              top: 18px;
              width: 60%;
            }

            @media only screen and (max-width: 432px) {
              left: 124px;
            }

            .slide-heading {
              color: rgb(0, 205, 179);
              font-size: 22px;
              line-height: 1.2;
              margin-bottom: 35px;
              text-align: left;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 18px;
                }
              }

              @media only screen and (max-width: 767px) {
                &.small-text {
                  margin-bottom: 12px;
                }

                font-size: 18px;
                margin-bottom: 12px;
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 16px;
                }
              }

              @media only screen and (max-width: 592px) {
                font-size: 20px;
              }

              @media only screen and (max-width: 432px) {
                &.small-text {
                  font-size: 15px;
                }
              }

              @media only screen and (max-width: 355px) {
                &.small-text {
                  font-size: 14px;
                }
              }
            }

            .slide-name {
              font-size: 20px;
              color: white;
              display: inline;
              padding: 0 10px;
              text-align: left;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 16px;
                }
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 14px;
                }
              }

              @media only screen and (max-width: 830px) {
                font-size: 18px;
              }

              @media only screen and (max-width: 592px) {
                font-size: 16px;
                margin-left: 50px;
              }

              @media only screen and (max-width: 440px) {
                font-size: 14px;
              }
            }

            .slide-title {
              color: white;
              font-size: 17px;
              margin-top: 10px;
              text-align: left;
              line-height: 1.5;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 15px;
                }
              }

              @media only screen and (max-width: 830px) {
                font-size: 18px;
              }

              @media only screen and (max-width: 767px) {
                margin-top: 0;
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 13px;
                }
              }

              @media only screen and (max-width: 592px) {
                font-size: 16px;
                margin-left: 50px;
                width: 80%;
              }

              @media only screen and (max-width: 440px) {
                font-size: 14px;
                margin-top: 0;
                line-height: 1.5;
              }
            }

            &.no-image {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70%;

              @media only screen and (max-width: 991px) {
                width: 100%;
              }

              @media only screen and (max-width: 575px) {
                padding: 0 20px;
              }

              .slide-heading {
                font-size: 29px;

                @media only screen and (max-width: 991px) {
                  font-size: 26px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 20px;
                  margin-bottom: 12px;
                }
              }

              .slide-name {
                @media only screen and (max-width: 592px) {
                  margin-left: 0;
                }
              }

              .slide-title {
                @media only screen and (max-width: 592px) {
                  margin-left: 0;
                  width: 100%;
                }
              }
            }
          }

          &.no-image {
            .teal-circle {
              width: 145px;
              left: 0;
              top: 0;
              position: absolute;

              @media only screen and (max-width: 1200px) {
                width: 115px;
              }

              @media only screen and (max-width: 991px) {
                width: 95px;
                left: -125px;
              }
            }

            .purple-circle {
              width: 300px;
              right: -50px;
              bottom: 0;
              position: absolute;

              @media only screen and (max-width: 1200px) {
                width: 242px;
              }

              @media only screen and (max-width: 991px) {
                width: 224px;
                right: -130px;
              }
            }
          }
        }
      }
    }

    ol.carousel-indicators {
      li {
        height: 15px;
        width: 15px;
        border: 2px solid rgb(0, 142, 124);
        background-color: rgba(255, 255, 255, 0);
        border-radius: 50%;
        padding: 0;
        background-clip: unset;
        transition-duration: 0.3s;

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }

        &.active {
          background-color: white;
          border: 2px solid white;
        }
      }

      @media only screen and (max-width: 592px) {
        bottom: -50px;

        li {
          &.active {
            background-color: rgb(0, 205, 179);
            border: 2px solid rgb(0, 205, 179);
          }
        }
      }
    }

    a.carousel-control-prev img,
    a.carousel-control-next img {
      width: 45px;
    }

    a.carousel-control-next,
    a.carousel-control-prev {
      @media only screen and (max-width: 1200px) {
        width: 10%;
      }

      @media only screen and (max-width: 830px) {
        display: none;
      }
    }

    a.carousel-control-prev.mobile,
    a.carousel-control-next.mobile {
      top: 348px;

      &.carousel-control-prev {
        left: 160px;
      }

      &.carousel-control-next {
        left: 350px;
      }
    }
  }

  .comp-section-two {
    position: relative;
    padding-top: 120px;

    .second-row {
      margin-bottom: 50px;

      .left-col {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }

      .right-col {
        text-align: right;

        @media only screen and (max-width: 991px) {
          text-align: left;
        }
      }
    }
  }

  .section-two-dots {
    height: 380px;
    width: 542px;
    top: 120px;
    left: 18px;

    @media only screen and (max-width: 991px) {
      width: 235px;
      height: 235px;
      left: -28px;
      top: 91px;
    }
    @media only screen and (max-width: 575px) {
      top: 96px;
      left: 15px;
      height: 160px;
      width: 160px;
    }
  }
}

.video-window-layover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 23, 57, 0.8);
  backdrop-filter: blur(5px);
  z-index: 3;
  display: none;
  transition-duration: 0.5s;

  .video-open-wrapper {
    position: absolute;
    left: 50%;
    top: 51%;
    transform: translate(-50%, -50%);
    z-index: 4;
    color: white;
    width: 60%;

    @media only screen and (max-width: 930px) {
      width: 80%;
    }

    @media only screen and (max-width: 516px) {
      width: 90%;
    }

    h2 {
      color: white;
      text-align: left;

      @media only screen and (max-width: 516px) {
        font-size: 20px;
      }
    }

    .video-close-btn {
      position: absolute;
      top: 14px;
      right: 0;
      width: 34px;
      cursor: pointer;
    }

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      height: auto;
    }

    .video-social-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

      p {
        display: inline;
        width: 95px;
        margin: 0;
        font-weight: bold;
        font-size: 20px;

        @media only screen and (max-width: 516px) {
          font-size: 18px;
        }
      }

      img {
        display: inline;
        width: 40px;
        height: 40px;
        margin-right: 15px;
        cursor: pointer;

        @media only screen and (max-width: 516px) {
          font-size: 30px;
        }
      }
    }
  }
}
