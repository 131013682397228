@import 'variables';
/* Page-wide styles */

.ty-container-fluid {
  padding: 0 !important;
  overflow: hidden !important;
}

.ty-container {
  padding: 0 !important;
}

.row.ty-split-row,
.row.ty-commitment-row,
.row.ty-full-row {
  margin-left: 0;
  margin-right: 0;
}

.hidden {
  display: none;
}

.ten-year-landing-wrapper {
  margin: 0;
  padding: 0 !important;
}

body.modal-open > .ten-year-landing-wrapper {
  filter: blur(10px);
}

/* End page-wide styles */
/* ===================================================================================== */
/* Hero section */

.ty-hero {
  height: 800px;
  width: 100%;
  background-size: 100% auto;
  background-image: url('/images/10-year-anniversary/landing-page/10-year-hero-bg.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}

.ty-hero-content {
  width: 60%;
}

.ty-hero-header {
  font-size: 65px;
  line-height: 1.3;
}

.ty-hero-subheader {
  font-size: 28px;
  line-height: 1.3;
}

.ty-map-image-wrapper {
  height: 800px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @media (max-width: 1350px) {
    height: 650px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.ty-map-image {
  object-fit: cover;
}

.ty-hero-video {
  position: absolute;
  width: 70vw;
  height: 40.25vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 700px;
  box-shadow: 0 40px 30px -20px grey;

  @media only screen and (max-width: 1600px) {
    top: 660px;
  }

  @media only screen and (max-width: 1500px) {
    top: 400px;
  }

  @media only screen and (max-width: 1350px) {
    width: 85vw;
    height: 48vw;
  }

  @media only screen and (max-width: 1200px) {
    top: 360px;
  }

  @media only screen and (max-width: 1000px) {
    top: 0;
    width: 100vw;
    height: 56.25vw;
    position: relative;
    padding: 40px;
  }

  @media only screen and (max-width: 800px) {
    padding: 0;
  }
}

.ty-subheader-mobile {
  padding: 30px;
  text-align: center;
}

.ty-subheader-mobile-wrapper {
  display: none;
}

/* End hero section */
/* ===================================================================================== */
/* Row sections */

.ty-full-row {
  position: relative;
  width: 100%;
  height: 430px;
  margin: 0;
}

.ty-full-row-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  z-index: 1;
}

.ty-full-row-title {
  flex: 50%;
  transition: 0.5s ease;
}

.ty-full-row-video {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ty-full-header {
  font-size: 65px;
  font-family: 'Invention Bold';
}

.ty-full-header.full-right {
  padding-left: 80px;
}

.ty-play-video-icon {
  height: 80px;
  width: auto;
  stroke: white;
  cursor: pointer;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: white;
  }
}

.ty-split-row {
  height: auto;
  position: relative;
}

.ty-row-box h2 {
  font-size: 48px;
  display: inline;
  z-index: 200;
}

.ty-box-arrow {
  width: 48px;
  height: 48px;
  display: inline;
  margin-left: 20px;
  transition-duration: 0.5s;
  position: absolute;
}

.ty-row-box.split-left {
  padding-left: calc(((100vw - 1140px) / 2) + 35px);
}

/* End row sections */
/* ===================================================================================== */
/* Full row styles */

.ty-full-img-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.ty-full-img {
  object-fit: cover;
}

/* Full row layovers */

.ty-full-layover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  left: -100%;
  transition: 0.5s ease;

  &#bravery-layover {
    background-color: rgb(13, 36, 63);
  }

  &#strength-layover {
    background-color: rgb(72, 73, 191);
  }

  &#resilience-layover {
    background-color: rgb(109, 206, 178);
  }
}

.full-slide-in {
  color: white;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  position: absolute;
  opacity: 0;
  top: 50%;
  transition: 0.5s ease;
  width: 50%;

  &.left-row {
    left: -150%;
  }

  &.right-row {
    left: 250%;
  }

  &#resilience-row {
    color: black;
  }
}

/* Full row hover states */

// .ty-full-row:hover {
//   .ty-full-layover {
//     left: 0;
//   }

//   .ty-play-video-icon {
//     stroke: rgb(193, 235, 70);

//     .cls-2 {
//       fill: rgb(193, 235, 70);
//     }
//   }

//   .ty-full-row-container {
//     .ty-full-row-title {
//       padding-bottom: 120px;
//       .ty-full-header {
//         color: rgb(207, 238, 47) !important;
//       }
//       .ty-full-header.resilience-text {
//         color: rgb(72, 73, 191) !important;
//       }
//     }

//     .full-slide-in {
//       left: 0;
//       opacity: 1;
//     }

//     .ty-play-video-icon {
//       stroke: rgb(207, 238, 47);
//       .cls-2 {
//         fill: rgb(207, 238, 47);
//       }
//     }

//     .ty-play-video-icon.resilience {
//       stroke: rgb(72, 73, 191);
//       .cls-2 {
//         fill: rgb(72, 73, 191);
//       }
//     }
//   }
// }

/* End full row styles */
/* ===================================================================================== */
/* Box background images/colors/layovers */

.ty-row-box {
  // z-index: 1;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 0 0 80px 80px;
  transition: 0.5s ease;
  height: 380px;
}

.ty-box-wrapper {
  padding-top: 300px;
  transition: 0.5s ease;
  width: 80%;
  z-index: 4;
  position: relative;
}

.ty-box-layover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.7;
}

.ty-box-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: left ease 0.5s, background 0.5s ease;
  display: flex;
  align-content: stretch;
  justify-content: stretch;
  z-index: 1;
}

.creativity-img-credit {
  position: absolute;
  color: white;
  font-weight: bold;
  right: 10px;
  text-align: right;
  bottom: -10px;
  font-size: 10px;
}

.ty-box-img {
  filter: grayscale(1);
  object-fit: cover;
  transition: filter 0.5s ease;
}

.ty-box-text {
  transition: 0.5s ease;
  font-family: 'Invention Bold';
}

/* Slide out styles */

.slide-out-wrapper {
  position: absolute;
  top: 30%;
  transition: 0.5s ease;
  opacity: 0;
  width: 700px;
  height: 0;
  padding-top: 30px;
  padding-left: 80px;

  &.left-box {
    left: -100%;
  }

  &.right-box {
    left: 100%;
  }
}

.slide-out-text {
  padding-top: 30px;
  font-size: 26px;
  line-height: 1.2;
  color: black;
  width: 80%;
  font-weight: bold;

  &.connection-text {
    color: white;
  }
}

.slide-out-link-wrapper {
  cursor: pointer;

  .slide-out-link {
    display: inline;
    color: rgb(18, 133, 124) !important;
    font-weight: bold;
    font-size: 28px;

    &.patience-text {
      color: rgb(192, 235, 69) !important;
    }

    &.connection-text {
      color: rgb(105, 183, 246) !important;
    }

    &.creativity-text {
      color: rgb(207, 238, 47) !important;
    }

    &.imagination-text {
      color: rgb(72, 73, 191) !important;
    }

    &.commitment-text {
      color: rgb(207, 238, 47) !important;
    }
  }

  .slide-out-arrow {
    height: 28px;
    width: 28px;
    // filter: brightness(0%);
    display: inline;
    margin-left: 15px;
    margin-top: -10px;
  }
}

.img-triangle-wrapper {
  z-index: 999;
  position: absolute;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;

  &.left-box {
    left: 0;
    transition: 0.5s ease;
  }

  &.right-box {
    left: 90%;
    transition: 0.5s ease;
  }
}

.img-triangle {
  height: 60px;
  background-color: transparent;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;

  &.left-box {
    border-right: 50px solid transparent;
  }

  &.right-box {
    border-left: 50px solid transparent;
  }

  &#empathy-triangle {
    border-left: 50px solid rgb(207, 238, 47);
  }

  &#patience-triangle {
    border-right: 50px solid rgb(18, 133, 124);
  }

  &#connection-triangle {
    border-left: 50px solid rgb(13, 36, 63);
  }

  &#creativity-triangle {
    border-right: 50px solid rgb(105, 183, 246);
  }

  &#imagination-triangle {
    border-left: 50px solid rgb(191, 237, 51);
  }

  &#commitment-triangle {
    border-right: 50px solid rgb(105, 140, 232);
  }
}

/* Individual box styles */

#empathy-box,
#empathy-layover {
  // background-color: rgb(124, 128, 104);
  background-color: rgb(207, 238, 47);
}

#patience-box,
#patience-layover {
  background-color: rgb(19, 133, 124);
}

#connection-box,
#connection-layover {
  background-color: rgb(13, 36, 63);
}

#creativity-box,
#creativity-layover {
  background-color: rgb(105, 183, 246);
}

#imagination-box,
#imagination-layover {
  background-color: rgb(191, 237, 51);
}

#commitment-box {
  background-color: rgb(105, 140, 232);
}

#commitment-box {
  background-color: rgb(105, 140, 232);

  .ty-box-img-wrapper {
    background-color: rgb(105, 140, 232);

    .commitment-box-timeline {
      position: absolute;
      transition: ease 0.5s;
      top: 40%;

      #Layer_2 {
        #Layer_1-2 {
          * {
            transition: ease 0.5s;
          }
        }
      }
    }
  }
}

/* Individual row styles */

.ty-strength-row {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-strength-bg.jpg');
}

.ty-resilience-row {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-resilience-bg.jpg');
}

/* End box background images/colors/layovers */
/* ===================================================================================== */
/* Box hover states */

// .ty-row-box:hover {
//   .ty-box-wrapper {
//     padding-top: 100px;
//     z-index: 999;

//     .ty-box-arrow {
//       opacity: 0;
//     }
//   }

//   .ty-box-layover {
//     z-index: 4;
//   }

//   .ty-box-img-wrapper {
//     z-index: 3;
//   }

//   .slide-out-wrapper {
//     opacity: 1;
//     left: 0;
//     z-index: 999;
//     height: auto;
//     -webkit-transition: 0.5s linear;
//     -moz-transition: 0.5s linear;
//     -ms-transition: 0.5s linear;
//     -o-transition: 0.5s linear;
//     transition: 0.5s linear;
//   }

//   .ty-box-img-wrapper {
//     left: 100%;
//     z-index: 5;

//     .ty-box-img {
//       filter: grayscale(0%);
//     }
//   }

//   .img-triangle-wrapper {
//     opacity: 1;
//     &.left-box {
//       left: 100%;
//     }
//     &.right-box {
//       left: calc(-100px);
//     }
//   }

//   &.right-box:hover {
//     .ty-box-img-wrapper {
//       left: -100%;
//     }
//   }

//   &#empathy-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         // color: rgb(88, 174, 102) !important;
//         color: rgb(18, 133, 124) !important;
//       }
//     }
//   }

//   &#patience-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         // color: rgb(207, 238, 48) !important;
//         color: rgb(192, 235, 69) !important;
//       }
//     }
//   }

//   &#connection-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(105, 183, 246) !important;
//       }
//     }
//   }

//   &#creativity-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(207, 238, 47) !important;
//       }
//     }
//   }

//   &#imagination-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(72, 73, 191) !important;
//       }
//     }
//   }

//   &#commitment-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(207, 238, 47) !important;
//       }
//     }
//     .ty-box-img-wrapper {
//       background-color: rgb(12, 36, 64);

//       .commitment-box-timeline {
//         width: 95%;
//         top: 44%;

//         #Layer_2 {
//           #Layer_1-2 {
//             .cls-1 {
//               fill: $limeColor !important;
//             }

//             .cls-12 {
//               fill: $tealColor !important;
//             }

//             .cls-3 {
//               fill: white;
//             }

//             .cls-13 {
//               fill: black;
//             }
//           }
//         }
//       }
//     }
//   }
// }

/* End box hover states */

.ty-commitment-header {
  margin: 0px auto;
}

.ty-commitment-header-text {
  display: inline;
  font-size: 60px;
  font-family: 'Invention Bold';
}

.ty-large-arrow {
  display: inline;
  height: 60px;
  width: 60px;
}

.ty-commitment-content {
  display: block;
  column-count: 2;
  margin-top: 180px;
  font-size: 23px;
  line-height: 1.3;

  @media only screen and (max-width: 1100px) {
    column-count: 1;
  }
}

@media (max-width: 1930px) {
  .ty-map-image-wrapper {
    height: 750px;
  }
}

@media (max-width: 1600px) {
  .ty-hero {
    height: 100%;
    width: auto;
    padding: 80px 0;
  }

  .ty-map-image-wrapper {
    height: 650px;
  }
}

@media (max-width: 1500px) {
  .ty-hero-header {
    font-size: 48px;
    line-height: auto;
  }

  .ty-hero-subheader {
    font-size: 18px;
    line-height: auto;
  }
}

@media (max-width: 1440px) {
  .ty-box-wrapper {
    padding-top: 250px;
  }
}

@media (max-width: 1280px) {
  // .row.ty-split-row {
  //   height: 350px;
  // }

  .ty-box-wrapper {
    padding-top: 250px;
  }
}

@media (max-width: 1200px) {
  .ty-hero-header {
    font-size: 35px;
  }

  .ty-map-image-wrapper {
    height: 600px;
  }

  .ty-hero {
    padding: 40px 0;
  }

  .ty-hero-content-container {
    width: 95%;
  }

  // .row.ty-split-row,
  // .row.ty-full-row {
  //   height: 300px;
  // }

  .slide-out-text {
    width: 60%;
  }
}

@media (max-width: 1100px) {
  .ty-map-image-wrapper {
    height: 580px;
  }
}

@media (max-width: 1024px) {
  .ty-hero-content {
    width: 60%;
  }

  .ty-hero {
    padding: 20px 0 20px;
  }

  .ty-hero-header {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .slide-out-text {
    width: 80%;
  }
  .ty-map-image-wrapper {
    height: 500px;
  }
}

@media (max-width: 850px) {
  .ty-hero-subheader {
    display: none;
  }

  .ty-subheader-mobile-wrapper {
    display: block;
  }

  .ty-hero-header {
    padding: 80px 0;
  }
}

@media (min-width: 768px) {
  /* Full row hover states */

  .ty-full-row:hover {
    .ty-full-layover {
      left: 0;
    }

    .ty-play-video-icon {
      stroke: rgb(193, 235, 70);

      .cls-2 {
        fill: rgb(193, 235, 70);
      }
    }

    .ty-full-row-container {
      .ty-full-row-title {
        padding-bottom: 120px;
        .ty-full-header {
          color: rgb(207, 238, 47) !important;
        }
        .ty-full-header.resilience-text {
          color: rgb(72, 73, 191) !important;
        }
      }

      .full-slide-in {
        left: 0;
        opacity: 1;
      }

      .ty-play-video-icon {
        stroke: rgb(207, 238, 47);
        .cls-2 {
          fill: rgb(207, 238, 47);
        }
      }

      .ty-play-video-icon.resilience {
        stroke: rgb(72, 73, 191);
        .cls-2 {
          fill: rgb(72, 73, 191);
        }
      }
    }
  }

  /* Box hover states */

  .ty-row-box:hover {
    .ty-box-wrapper {
      padding-top: 100px;
      z-index: 999;

      .ty-box-arrow {
        opacity: 0;
      }
    }

    .ty-box-layover {
      z-index: 4;
    }

    .ty-box-img-wrapper {
      z-index: 3;
    }

    .slide-out-wrapper {
      opacity: 1;
      left: 0;
      z-index: 999;
      height: auto;
      -webkit-transition: 0.5s linear;
      -moz-transition: 0.5s linear;
      -ms-transition: 0.5s linear;
      -o-transition: 0.5s linear;
      transition: 0.5s linear;
    }

    .ty-box-img-wrapper {
      left: 100%;
      z-index: 5;

      .ty-box-img {
        filter: grayscale(0%);
      }
    }

    .img-triangle-wrapper {
      opacity: 1;
      &.left-box {
        left: 100%;
      }
      &.right-box {
        left: calc(-100px);
      }
    }

    &.right-box:hover {
      .ty-box-img-wrapper {
        left: -100%;
      }
    }

    &#empathy-box {
      .ty-box-wrapper {
        .ty-box-text {
          // color: rgb(88, 174, 102) !important;
          color: rgb(18, 133, 124) !important;
        }
      }
    }

    &#patience-box {
      .ty-box-wrapper {
        .ty-box-text {
          // color: rgb(207, 238, 48) !important;
          color: rgb(192, 235, 69) !important;
        }
      }
    }

    &#connection-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(105, 183, 246) !important;
        }
      }
    }

    &#creativity-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(207, 238, 47) !important;
        }
      }
    }

    &#imagination-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(72, 73, 191) !important;
        }
      }
    }

    &#commitment-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(207, 238, 47) !important;
        }
      }
      .ty-box-img-wrapper {
        background-color: rgb(12, 36, 64);

        .commitment-box-timeline {
          width: 95%;
          top: 44%;

          #Layer_2 {
            #Layer_1-2 {
              .cls-1 {
                fill: $limeColor !important;
              }

              .cls-12 {
                fill: $tealColor !important;
              }

              .cls-3 {
                fill: white;
              }

              .cls-13 {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  /* End box hover states */
}

@media (max-width: 768px) {
  .ty-box-img {
    filter: initial;
  }

  .ty-full-row-container {
    .full-slide-in {
      left: 0;
      opacity: 1;
    }

    .ty-full-row-title {
      padding-bottom: 120px;
      .ty-full-header {
        color: rgb(207, 238, 47) !important;
      }
      .ty-full-header.resilience-text {
        color: rgb(72, 73, 191) !important;
      }
    }

    .ty-play-video-icon {
      stroke: rgb(207, 238, 47);
      .cls-2 {
        fill: rgb(207, 238, 47);
      }
    }

    .ty-play-video-icon.resilience {
      stroke: rgb(72, 73, 191);
      .cls-2 {
        fill: rgb(72, 73, 191);
      }
    }
  }

  .ty-box-wrapper {
    padding-top: 100px;
  }

  #empathy-box {
    .ty-box-text {
      color: rgb(18, 133, 124) !important;
    }
  }

  #patience-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(192, 235, 69) !important;
      }
    }
  }

  #connection-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(105, 183, 246) !important;
      }
    }
  }

  #creativity-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(207, 238, 47) !important;
      }
    }
  }

  #imagination-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(72, 73, 191) !important;
      }
    }
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    opacity: 1;
    left: 0;
    z-index: 999;
  }

  .ty-box-arrow {
    display: none;
  }

  #commitment-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(207, 238, 47) !important;
      }
    }
    .ty-box-img-wrapper {
      background-color: rgb(12, 36, 64);

      .commitment-box-timeline {
        width: 95%;
        top: 44%;

        #Layer_2 {
          #Layer_1-2 {
            .cls-1 {
              fill: $limeColor !important;
            }

            .cls-12 {
              fill: $tealColor !important;
            }

            .cls-3 {
              fill: white;
            }

            .cls-13 {
              fill: black;
            }
          }
        }
      }
    }

    .ty-box-wrapper {
      position: absolute;
      top: 90%;
      width: 100%;
    }

    .slide-out-wrapper.right-box {
      position: absolute;
      top: 120%;
      width: 100%;
    }
  }

  .box-commitment {
    height: 350px;
    background-color: #698ce8;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .ty-hero-header {
    font-size: 32px;
    padding: 10px 0;
  }

  .ty-map-image-wrapper {
    height: 400px;
  }

  .ty-full-row-container .full-slide-in {
    left: 35px;
    font-size: 24px;
  }

  .ty-full-row-container .ty-full-row-title {
    padding-left: 25px;
  }

  .slide-out-text {
    font-size: 22px;
    text-align: left !important;
  }

  // .ty-row-box {
  //   padding-left: 30px;
  // }

  .ty-box-wrapper {
    padding-top: 60px;
  }

  .ty-full-header {
    font-size: 48px;
  }

  .ty-play-video-icon {
    height: 55px;
  }

  .slide-out-wrapper {
    width: 100%;
    // padding-left: 30px;
    top: 20%;
  }

  #commitment-box .ty-box-wrapper {
    padding-top: 105px;
  }

  #bravery .ty-full-row-title {
    padding-left: 0px;
  }

  .ty-row-box {
    padding: 0 0 30px 30px;
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    left: -10%;
  }
}

@media (max-width: 448px) {
  .ty-hero-header {
    font-size: 28px;
  }

  .ty-play-video-icon {
    height: 45px;
  }

  .ty-row-box {
    padding: 0 0 30px 30px;
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    left: -13%;
  }

  .ty-full-row-container .ty-full-row-title {
    padding-left: 33px;
  }

  #bravery .ty-full-row-title {
    padding-left: 20px;
  }

  .ty-full-row-container .full-slide-in {
    left: 35px;
    font-size: 18px;
  }

  .ty-full-header,
  .ty-row-box h2 {
    font-size: 40px;
  }

  .slide-out-link-wrapper .slide-out-link {
    font-size: 22px;
  }

  .slide-out-text {
    font-size: 20px;
  }

  .slide-out-text {
    width: 90%;
  }
}

/* End media queries */
/* ===================================================================================== */
/* Modals */

.share-icon:hover {
  cursor: pointer;
}

.modal-dialog {
  max-width: 100% !important;
  width: 75% !important;

  @media only screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.modal-content {
  border-radius: 0 !important;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  background-color: transparent;
  font-size: 130px;
  color: $tealColor;

  &.commitment-modal {
    color: $limeColor;
  }

  @media only screen and (max-width: 675px) {
    right: 10px;
    top: 10px;
    font-size: 80px;
  }
}

.modal-container {
  width: 80%;
  margin: auto;

  @media only screen and (max-width: 1680px) {
    &.wide {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1600px) {
    &.wide {
      width: 90%;
    }
  }

  @media only screen and (max-width: 1500px) {
    &.wide {
      width: 95%;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 95%;
  }
}

.modal-wrapper {
  * {
    border: none;
  }
}

.modal-hero-wrapper {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-patience-modal-header.png');
  width: 100%;
  padding-top: 40px;
  padding-bottom: 300px;
  position: relative;

  &#commitment-modal {
    background-image: url('/images/10-year-anniversary/landing-page/10-year-commitment-hero-wrapper.png');
  }

  @media only screen and (max-width: 1200px) {
    padding-bottom: 200px;
  }

  @media only screen and (max-width: 446px) {
    padding-bottom: 100px;
  }
}

.modal-header {
  color: rgb(193, 237, 50);
  font-size: 55px;
  font-weight: bold;
  padding-left: 16px;

  @media only screen and (max-width: 675px) {
    font-size: 40px;
  }
}

.modal-subheader {
  color: white;
  font-size: 40px;
  font-weight: normal;
  line-height: 1.3;

  @media only screen and (max-width: 675px) {
    font-size: 35px;
  }
}

.modal-hero-stripe {
  width: 100%;
  height: 60px;
  background-color: rgb(216, 216, 216);
}

.modal-hero-img {
  width: 90%;
  height: auto;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  top: 300px;

  &.video-modal {
    top: 230px;
    width: 60vw;
    height: 33.75vw;
  }

  &.modal-carousel {
    width: 100%;
    top: 280px;
    height: 550px;
  }

  &.low-video {
    top: 270px;
  }

  @media only screen and (max-width: 1450px) {
    &.modal-carousel {
      height: 450px;
    }
  }

  @media only screen and (max-width: 1200px) {
    &.modal-carousel {
      height: 400px;
    }

    &.commitment-modal {
      top: 250px;
    }

    // &.commitment-modal {

    // }
  }

  @media only screen and (max-width: 1000px) {
    &.video-modal {
      top: 280px;
      width: 70vw;
      height: 39.375vw;
    }

    &.modal-carousel {
      height: 330px;
    }
  }

  @media only screen and (max-width: 900px) {
    top: 330px;

    &.commitment-modal {
      top: 260px;
    }
  }

  @media only screen and (max-width: 879px) {
    &.modal-carousel {
      top: 320px;
      height: 260px;
    }
  }

  @media only screen and (max-width: 760px) {
    &.video-modal {
      position: relative;
      top: 0;
      margin-top: 0px;
      width: 100%;
      height: 39.75vw;
    }

    &.connection-modal {
      top: 280px;
    }
  }

  @media only screen and (max-width: 650px) {
    &.patience-modal {
      top: 265px;
    }

    &.video-modal {
      height: 56.25vw;
    }
  }

  @media only screen and (max-width: 640px) {
    top: 210px;

    &.modal-carousel {
      top: 220px;
    }
  }

  @media only screen and (max-width: 600px) {
    top: 220px;

    &.modal-carousel {
      top: 260px;
    }
  }

  @media only screen and (max-width: 563px) {
    &.creativity-modal {
      top: 270px;
    }
  }

  @media only screen and (max-width: 541px) {
    &.modal-carousel {
      height: 220px;
    }
  }

  @media only screen and (max-width: 522px) {
    top: 220px;
    width: 100%;
  }

  @media only screen and (max-width: 494px) {
    top: 260px;

    &.creativity-modal {
      top: 220px;
    }

    &.connection-modal {
      top: 240px;
    }

    &.commitment-modal {
      top: 220px;
    }
  }

  @media only screen and (max-width: 373px) {
    &.commitment-modal {
      top: 260px;
    }
  }

  @media only screen and (max-width: 358px) {
    top: 310px;

    &.connection-modal {
      top: 280px;
    }
  }

  @media only screen and (max-width: 320px) {
    &.patience-modal {
      top: 360px;
    }
  }
}

.modal-body-section {
  margin-top: 35%;

  &.video-modal {
    margin-top: 32%;
  }

  &.imagination-modal {
    margin-top: 45%;
  }

  &.resilience-modal {
    margin-top: 38%;
  }

  @media only screen and (max-width: 1600px) {
    &.video-modal {
      margin-top: 25%;
    }
  }

  @media only screen and (max-width: 1625px) {
    margin-top: 28%;

    &.imagination-modal {
      margin-top: 50%;
    }

    &.commitment-modal {
      margin-top: 33%;
    }
  }

  @media only screen and (max-width: 1500px) {
    margin-top: 33%;

    &.commitment-modal {
      margin-top: 28%;
    }
  }

  @media only screen and (max-width: 1450px) {
    &.imagination-modal {
      margin-top: 40%;
    }
  }

  @media only screen and (max-width: 1400px) {
    &.video-modal {
      margin-top: 18%;
    }
  }

  @media only screen and (max-width: 1316px) {
    margin-top: 25%;

    &.low-modal {
      margin-top: 10%;
    }
  }

  @media only screen and (max-width: 1200px) {
    &,
    &.creativity-modal,
    &.connection-modal {
      margin-top: 33%;
    }

    &.imagination-modal {
      margin-top: 30%;
    }

    &.video-modal {
      margin-top: 25%;
    }

    &.commitment-modal {
      margin-top: 33%;
    }
  }

  @media only screen and (max-width: 1156px) {
    margin-top: 30%;
  }

  @media only screen and (max-width: 1000px) {
    &.imagination-modal {
      margin-top: 30%;
    }

    &.connection-modal {
      margin-top: 30%;
    }

    &.video-modal {
      margin-top: 25%;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 20%;
  }

  @media only screen and (max-width: 1070px) {
    &.bravery-modal {
      margin-top: 15%;
    }
  }

  @media only screen and (max-width: 1000px) {
    &.imagination-modal {
      margin-top: 20%;
    }
  }

  @media only screen and (max-width: 920px) {
    &.commitment-modal {
      margin-top: 28%;
    }
  }

  @media only screen and (max-width: 818px) {
    &.video-modal {
      margin-top: 10%;
    }
  }

  @media only screen and (max-width: 766px) {
    &.connection-modal {
      margin-top: 23%;
    }
  }

  @media only screen and (max-width: 760px) {
    &.imagination-modal {
      margin-top: 32%;
    }

    &.creativity-modal {
      margin-top: 25%;
    }

    &.video-modal {
      margin-top: 0 !important;
    }
  }

  @media only screen and (max-width: 640px) {
    margin-top: 10%;

    &.commitment-modal {
      margin-top: 22%;
    }
  }

  @media only screen and (max-width: 446px) {
    &,
    &.connection-modal {
      padding-bottom: 100px;
      margin-top: 30%;
    }

    &.commitment-modal {
      margin-top: 51%;
    }

    &.commitment-modal {
      margin-top: 40%;
    }
  }
}

@media (max-width: 1500px) {
  .modal-stats-section {
    padding-bottom: 200px;
  }
}

.modal-text-wrapper {
  margin-top: 50px;
}

.modal-text {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.divider {
  width: 30%;
  height: 4px;

  &.teal-divider {
    background: $tealColor;
  }

  &.white-divider {
    background: white;
  }

  @media only screen and (max-width: 760px) {
    display: none;
  }
}

.modal-share-header {
  font-weight: bold;
  font-family: 'Invention Bold';
}

.share-icon-wrapper {
  display: flex;
  justify-content: space-around;
}

.share-icon {
  height: 50px;
  width: 50px;
  display: inline;
}

.modal-stats-section {
  background-color: rgb(216, 216, 216);
  padding-top: 70px;
  margin-top: 70px;
  padding-bottom: 200px;

  @media only screen and (max-width: 1500px) {
    padding-bottom: 200px;
  }
}

.stats-row {
  margin-top: 70px;
  padding-bottom: 30px;
  border-bottom: 2px dotted rgb(58, 131, 124);
}

.stat-column-wrapper {
  padding: 0px 50px 10px 50px;
}

.stat-column-number {
  position: relative;
}

.stat-column-wrapper + .stat-column-wrapper {
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 1px;
    height: 80%;
    border-right: 2px dotted rgb(58, 131, 124);
    z-index: 10;
  }
}

.stat-number-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 100px;
  font-weight: bold;
  color: rgb(27, 39, 65);
  font-family: 'Invention Black';

  &.large-stat-wrapper {
    .stat-column-number > span {
      font-size: 200px;
      @media screen and (max-width: 1800px) {
        font-size: 165px;
      }
      @media screen and (max-width: $break-xlarge) {
        font-size: 125px;
      }
      @media screen and (max-width: 1400px) {
        font-size: 100px;
      }
    }

    .stat-number-bg {
      width: 100%;
      top: 40%;
    }
  }
}

.stat-column-number {
  padding-bottom: 10px;
  z-index: 10;

  @media screen and (max-width: $break-xlarge) {
    padding-bottom: 20px;
  }
}

.stat-number-bg {
  position: absolute;
  background-color: rgb(144, 204, 177);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  z-index: 0;
}

.stat-number-label-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-weight: bold;
  color: rgb(27, 39, 65);
  font-family: 'Invention Black';
  align-items: center;
  font-size: 70px;

  .stat-column-number {
    font-size: 70px;
    z-index: 10;
    @media screen and (max-width: $break-xlarge) {
      font-size: 65px;
    }

    @media screen and (max-width: $break-large) {
      font-size: 50px;
    }
  }

  .stat-column-label {
    font-size: 20px;
    // margin-left: 70px;
    text-align: center;
    // margin-top: -15px;
    z-index: 1;
    text-align: left;
    position: absolute;
    top: 65px;
    left: 160px;
    width: 200px;
    @media screen and (max-width: $break-medium) {
      font-size: 16px;
      top: 52px;
      width: 185px;
    }
  }

  .stat-column-label.nine-thou-lives {
    left: 135px;
  }

  .stat-column-label.label1 {
    top: 80px;
    left: 180px;
    @media screen and (max-width: $break-medium) {
      top: 75px;
      left: 165px;
    }
  }

  .stat-column-label.label2 {
    left: 180px;
    @media screen and (max-width: $break-medium) {
      left: 165px;
    }
  }

  .stat-column-label.label3 {
    left: 262px;
    @media screen and (max-width: $break-medium) {
      left: 235px;
    }
  }

  .stat-number-bg {
    top: 26px;
    height: 50px;
  }
}

.stat-column-text {
  font-weight: bold;
  padding-top: 28px;
  line-height: 1.5;
  font-size: 20px;
}
.stat-text-large {
  color: rgb(58, 131, 124);
  font-weight: bold;
  font-size: 28px;
  line-height: 0.5;
}

.large-teal-text {
  color: $tealColor;
  font-size: 26px;
}

.supplies-count {
  padding: 0 50px;
  @media screen and (max-width: $break-large) {
    padding: 0 20px;
  }
  @media screen and (max-width: $break-medium) {
    padding: 0 50px;
  }
}

/* Timeline */

.modal-timeline-wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  .timeline-item-row {
    .timeline-item-wrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .timeline-item-text {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;

        &::after {
          content: ' ';
          width: 1px;
          border-right: $darkBlueColor 3px dotted;
          display: block;
          margin: 10px auto 0 auto;
          z-index: 1;
        }

        &.timeline-one::after {
          height: 100px;
        }

        &.timeline-two::after {
          height: 40px;
        }

        &.timeline-three::after {
          height: 60px;
        }

        &.timeline-four::after {
          height: 40px;
        }

        &.timeline-five::after {
          height: 60px;
        }
      }

      .timeline-circle {
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50px;
        border: solid $tealColor 3px;
        background-color: rgb(216, 216, 216);
        z-index: 10;
        bottom: -9px;
      }

      @media only screen and (max-width: 1175px) {
        .timeline-item-text {
          font-size: 16px;
        }
      }

      @media only screen and (max-width: 991px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 150px;

        & + .timeline-item-wrapper {
          border-top: dotted $darkBlueColor 2px;
        }

        .timeline-item-text {
          text-align: left;
          font-size: 20px;
        }

        .timeline-item-text::after {
          display: none;
        }

        .timeline-circle {
          display: none;
        }
      }

      @media only screen and (max-width: 470px) {
        .timeline-item-text {
          font-size: 18px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      display: block !important;
      margin: 0 15px;
    }
  }

  .timeline-date-row {
    border-top: 3px dotted $tealColor;
    padding-top: 30px;
    z-index: 1;

    .timeline-date-text {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: row-reverse;

    .timeline-date-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: none;
      border-right: 3px dotted $tealColor;
      padding-top: 0;

      .timeline-date-text {
        height: 150px;

        @media only screen and (max-width: 470px) {
          font-size: 20px;
        }
      }
    }
  }
}
/* End Timeline */

@media (max-width: 992px) {
  .divider {
    width: 50%;
  }

  .stat-column-wrapper + .stat-column-wrapper {
    &::before {
      display: none;
    }
  }

  .stat-column-text {
    text-align: center;
  }

  .modal-share-header {
    text-align: center;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .modal-stats-section {
    padding-bottom: 100px;
  }
}

@media (max-width: 755px) {
  .modal-subheader {
    font-size: 35px;
  }
}

@media (max-width: 640px) {
  .modal-subheader {
    text-align: left !important;
    font-size: 27px;
  }
}

/* Creativity Modal */
.life-bank-wrapper::after {
  content: ' ';
  height: 80%;
  position: absolute;
  top: 0;
  width: 1px;
  border-right: 2px dotted $tealColor;
  right: 0;

  @media screen and (max-width: $break-medium) {
    border-right: none;
  }
}

.penda-health-wrapper {
  padding-left: 50px;

  @media only screen and (max-width: 768px) {
    padding: 35px 0;
  }

  .row {
    .col-lg-4 {
      .stat-number-wrapper {
        .stat-number-bg {
          width: 150px;
        }
      }
    }
  }
}

.life-bank-wrapper {
  padding-right: 30px;

  .life-bank-title {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    border-bottom: 2px $tealColor dotted;
    padding-bottom: 40px;

    &::after {
      display: none;
    }

    .life-bank-title {
      font-size: 20px;
      margin-bottom: 12px;
      text-align: center;
    }

    .life-bank-caption {
      font-size: 20px;
      padding: 10px 0;
      text-align: center;
    }
  }
}

.penda-health-para {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5;
  padding-top: 40px;
  margin-bottom: 0;

  @media screen and (max-width: $break-large) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
}

.unjani-wrapper {
  @media screen and (max-width: $break-medium) {
    margin-top: 40px !important;
  }
  .unjani-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .top-para,
  .bottom-para {
    font-size: 20px;
    @media screen and (max-width: $break-xlarge) {
      font-size: 18px;
    }
    @media screen and (max-width: $break-large) {
      font-size: 16px;
    }
    @media screen and (max-width: $break-medium) {
      text-align: left;
    }
  }

  .stat-number-wrapper.large-stat-wrapper.with-label {
    display: flex;
    flex-direction: column;
    text-align: center;

    .stat-column-number {
      padding-bottom: 0;
    }

    p {
      z-index: 1;
      text-align: center;
    }

    .stat-column-label {
      text-align: center;
      font-size: 30px;
      // margin-top: -10px;
      @media screen and (max-width: $break-large) {
        font-size: 22px;
      }
    }

    .stat-number-bg {
      bottom: 10px;
    }
  }

  .stat-bottom-label {
    font-size: 22px;
    padding-right: 110px;
    padding-top: 10px;
    @media screen and (max-width: $break-xlarge) {
      padding-right: 55px;
    }

    @media screen and (max-width: $break-large) {
      font-size: 18px;
    }
  }

  .bottom-para {
    margin-top: 20px;
    line-height: 1.6;
    font-size: 20px;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 35px;
    .top-para {
      text-align: center;
      font-size: 20px;
    }

    .unjani-stat-wrapper {
      align-items: center;
    }

    .stat-bottom-label {
      padding: 0;
    }

    .bottom-para {
      text-align: center;
    }
  }
}

.unjani-right-wrapper {
  display: flex;
  flex-direction: column;

  .unjani-right-row {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 991px) {
      justify-content: flex-start;
    }

    &.mid-row {
      margin-right: 150px;
    }
  }

  .unjani-right-stat-wrapper {
    display: flex;
    flex-direction: column;

    .stat-number-label-wrapper {
      display: block;
      flex-direction: row;

      @media only screen and (max-width: 556px) {
        .stat-column-number {
          font-size: 70px;
        }

        .stat-number-bg {
          height: 50px;
        }

        .stat-column-number {
          .stat-column-label {
            font-size: 17px;
          }
        }

        &.top-row {
          .stat-number-bg {
            width: 145px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 120px;
              top: 50px;
            }
          }
        }
        &.mid-row {
          .stat-number-bg {
            width: 145px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 125px;
              top: 30px;
            }
          }
        }
        &.bottom-row {
          .stat-number-bg {
            width: 200px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 2180px;
              top: 30px;
            }
          }
        }
      }
    }

    p {
      font-weight: bold;

      @media only screen and (max-width: 1200px) {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    .stat-number-bg {
      height: 60px;
      top: 40%;
      // width: 78%;
      width: 200px;
      margin-left: -10px;
      z-index: 1;
    }

    .stat-number-bg.stat-bg3 {
      width: 285px;
      @media screen and (max-width: 991px) {
        width: 255px;
      }
    }

    .stat-column-number {
      display: inline;
      font-size: 100px;
      @media screen and (max-width: 991px) {
        font-size: 85px;
      }
    }

    // .stat-column-label {
    //   display: inline;
    //   margin-left: -15px;
    //   text-align: left;
    //   margin-top: 0;
    // }
  }
}

.row.stats-row.jacaranda-row {
  @media only screen and (max-width: 640px) {
    padding-top: 35px;
  }
}

.stat-number-wrapper.jacaranda-row {
  .stat-number-bg {
    width: 110%;

    @media screen and (max-width: $break-large) {
      height: 70px;
      top: 24%;
    }
    @media only screen and (max-width: 1400px) {
      top: 25%;
      height: 80px;
    }
  }
}

.jacaranda-para {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  padding-left: 30px;

  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
}

.trail1 {
  position: absolute;
  top: 67%;
  height: 350px;
  left: 35%;
  z-index: 0;
}

.trail2 {
  height: 150px;
  position: absolute;
  left: 80%;
  top: 67%;
  z-index: 0;
}

.trail3 {
  height: 150px;
  position: absolute;
  top: 72%;
  left: 46%;
  z-index: 0;
}

.creativity-trail {
  @media only screen and (max-width: 1625px) {
    &.trail1 {
      top: 65.5%;
    }

    &.trail2 {
      top: 66%;
    }

    &.trail3 {
      top: 7%;
    }
  }

  @media only screen and (max-width: 1600px) {
    &.trail1 {
      top: 68%;
    }

    &.trail2 {
      top: 69%;
    }

    &.trail3 {
      top: 73%;
    }
  }

  @media only screen and (max-width: 1500px) {
    &.trail1 {
      top: 69%;
    }

    &.trail2 {
      top: 70%;
    }

    &.trail3 {
      top: 74%;
    }

    @media only screen and (max-width: 1466px) {
      &.trail2 {
        top: 69%;
      }

      &.trail3 {
        top: 73%;
        left: 44%;
      }
    }

    @media only screen and (max-width: 1371px) {
      &.trail1 {
        top: 67%;
      }

      &.trail2 {
        top: 67%;
      }

      &.trail3 {
        top: 71%;
      }
    }

    @media only screen and (max-width: 1309px) {
      &.trail1 {
        top: 66%;
      }

      &.trail2 {
        top: 66.3%;
      }

      &.trail3 {
        top: 70.5%;
      }
    }

    @media only screen and (max-width: 1200px) {
      &.trail1,
      &.trail2,
      &.trail3 {
        display: none !important;
      }
    }
  }
}

/* Connection popup */

.abraco-stats-top-row,
.abraco-stats-mid-row,
.abraco-stats-bottom-row {
  margin-left: 0;
  margin-right: 0;
}

.abraco-header-row {
  &.row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px;
    border-bottom: none;
  }

  .abraco-subheader {
    font-size: 20px;
    font-weight: bold;
  }

  .abraco-header {
    font-weight: bold;
    font-size: 30px;
  }
}

.abraco-stats-top-row {
  height: 266px;
  padding-bottom: 60px;

  .abraco-top-left-wrapper {
    display: flex;
    justify-content: center;
    border-right: 2px dotted $tealColor;

    .abraco-right-arrow-wrapper {
      width: 40px;
      position: relative;

      img {
        position: absolute;
        top: 65px;
        left: 5px;

        @media only screen and (max-width: 1525px) {
          top: 48px;
        }
        @media only screen and (max-width: 1420px) {
          top: 55px;
        }
      }
    }

    .abraco-top-left-stat-wrapper {
      padding: 0;

      &.stat1 {
        width: 220px;
      }

      &.stat2 {
        width: 281px;
        margin-left: 10px;
      }

      .abraco-top-left-stat {
        &.stat1 {
          flex: 1;
        }

        &.stat2 {
          flex: 2;
        }

        padding-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .abraco-top-left-text {
          font-weight: bold;
          width: 80%;
          font-size: 18px;
          padding-top: 15px;
        }

        .stat-number-wrapper {
          padding: 0px 10px;
          font-size: 80px;

          .stat-number-bg {
            top: 40%;
            width: 100%;
          }

          @media only screen and (max-width: 1525px) {
            font-size: 60px;
          }

          @media only screen and (max-width: 1420px) {
            font-size: 65px;

            &.stat-number-bg {
              top: 30%;
            }
          }
        }
      }

      @media only screen and (max-width: 1420px) {
        &.stat1 {
          width: 170px;
          padding-right: 15px;
        }

        &.stat2 {
          width: 216px;
          padding-left: 25px;
        }
      }
    }
  }

  .abraco-top-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .abraco-top-right-stat {
      .stat-number-wrapper {
        font-size: 115px;

        @media only screen and (max-width: 1380px) {
          font-size: 90px;
        }
      }
    }

    .abraco-top-right-text {
      font-size: 24px;
      text-align: center;
      width: 80%;
      padding-top: 15px;
      line-height: 1.2;
    }

    @media only screen and (max-width: 1525px) {
      font-size: 95px;

      .abraco-top-right-text {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    height: auto;
    margin-top: 20px;

    .abraco-top-left-wrapper {
      border: none;
    }
  }
}

.abraco-stats-mid-row {
  margin: 0;
  padding: 60px 0px;
  .abraco-mid-left-wrapper {
    display: flex;
    justify-content: center;

    .abraco-mid-left-stat {
      align-items: flex-end;

      .stat-number-wrapper {
        font-size: 120px;
        padding: 0 10px;
        align-items: flex-end;

        .abraco-percent-wrapper {
          display: flex;
          height: 100%;
          align-items: flex-start;
          z-index: 1;

          span {
            font-size: 60px;
            padding-bottom: 60px;
          }
        }

        .abraco-down-arrow-wrapper {
          width: 40px;
          height: 100%;
          z-index: 10;
          position: relative;

          svg {
            position: absolute;
            bottom: 30px;
          }
        }
      }

      .abraco-stat-percent {
        font-size: 50px;
        padding-bottom: 54px;
      }

      .abraco-down-arrow {
        z-index: 10;
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .abraco-mid-right-wrapper {
    display: flex;
    align-items: center;

    .abraco-mid-right-text {
      font-size: 20px;
      font-weight: bold;
    }

    @media only screen and (max-width: 1200px) {
      margin-top: 30px;
      text-align: center;
    }
  }
}

.abraco-stats-bottom-row {
  border-bottom: none;

  .abraco-bottom-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.stat1,
    &.stat2 {
      border-right: 2px dotted $tealColor;
    }

    .abraco-bottom-stat {
      .stat-number-wrapper {
        font-size: 130px;

        @media only screen and (max-width: 1600px) {
          font-size: 120px;
        }

        @media only screen and (max-width: 1200px) {
          font-size: 100px;
        }
      }

      .stat-number-bg {
        margin-left: -10%;
        width: 120%;
      }
    }

    .abraco-bottom-stat-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      padding-top: 15px;
      width: 80%;
    }

    @media only screen and (max-width: 992px) {
      &.stat1,
      &.stat2 {
        border: none;
      }

      & + .abraco-bottom-stat-wrapper {
        margin-top: 60px;
      }
    }
  }
}

/* Commitment Popup */

.modal-body-section.commitment-modal {
  background-color: transparent !important;
  padding-bottom: 100px !important;
}

/* Bravery Modal */

.modal-hero-wrapper.video-modal {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-map-bg.jpg');

  .modal-header {
    color: rgb(1, 134, 124);
  }

  .modal-subheader {
    color: black;
    font-weight: bold;
  }

  @media only screen and (max-width: 760px) {
    padding-bottom: 40px;
  }
}

.modal-stats-section.video-modal {
  margin-top: 0;
  background-color: $tealColor;
  padding-bottom: 100px;

  @media only screen and (max-width: 1600px) {
    padding-top: 90px;
  }

  @media only screen and (max-width: 1400px) {
    padding-top: 130px;
  }

  @media only screen and (max-width: 1200px) {
    padding-top: 160px;
  }

  @media only screen and (max-width: 760px) {
    padding-top: 20px;
  }
}

.modal-text.video-modal {
  color: white;
  font-weight: normal;
}

.modal-share-header.video-modal {
  color: white;
}

.share-icon-wrapper.video-modal {
  .share-icon {
    height: 40px;
    width: auto;
  }
}

/* Imagination Modal */

.modal-carousel {
  .carousel-control-prev {
    left: 2.5%;
    width: auto;

    @media only screen and (max-width: 850px) {
      left: 1%;
    }
  }

  .carousel-control-next {
    right: 2.5%;
    width: auto;

    @media only screen and (max-width: 850px) {
      right: 1%;
    }
  }

  .carousel-inner {
    width: 80% !important;
    margin: auto !important;
    height: 100% !important;

    @media only screen and (max-width: 850px) {
      width: 90% !important;
    }

    @media only screen and (max-width: 650px) {
      width: 100% !important;
    }
  }

  .carousel-item {
    height: 100% !important;
    width: 100% !important;
  }
}

.modal-hero-wrapper.imagination-modal {
  padding-bottom: 150px;

  .modal-carousel {
    ol.carousel-indicators {
      bottom: -50px;

      li {
        width: 20px;
        height: 20px;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 50%;
        opacity: 1;
        border: 3px solid white;
        background: transparent;
        padding: 0 !important;

        &.active {
          background: white;
        }

        @media only screen and (max-width: 1000px) {
          width: 15px;
          height: 15px;
        }

        @media only screen and (max-width: 800px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.modal-stats-section.imagination-modal {
  padding-top: 200px;

  @media only screen and (max-width: 1625px) {
    padding-top: 260px;
  }

  @media only screen and (max-width: 1500px) {
    padding-top: 320px;
  }
}
